import React from "react";
import { CrumbTrail } from "../../interfaces"
import { createDisplayName, uppercase } from "../../utils"

const styles = require("./Breadcrumbs.module.scss");

interface Props {
  trail: CrumbTrail
}

const Breadcrumbs: React.FunctionComponent<Props> = ({trail}) => {

  const root = createDisplayName(trail[0]);
  const resource = createDisplayName(trail[1]);

	return (
		<div className={styles.Breadcrumbs}>
      <h1>
        {uppercase(root)}
      </h1>
      <svg xmlns="http://www.w3.org/2000/svg" width="6.501" height="10.842" viewBox="0 0 6.501 10.842">
        <g transform="translate(-481.331 -510.026)">
          <path d="M482.08,520.868a.749.749,0,0,1-.525-1.282l4.432-4.366-4.4-3.884a.748.748,0,1,1,.99-1.122l5,4.415a.748.748,0,0,1,.03,1.095l-5,4.93A.745.745,0,0,1,482.08,520.868Z" fill="#5583ed"/>
        </g>
      </svg>
      <h1 className={styles.Active}>
        {resource}
      </h1>
		</div>
	);
};

export default Breadcrumbs;
