import React from "react";
import { SchemaProperty } from "../../interfaces"
import classNames from "classnames"

const styles = require("./Properties.module.scss");

interface Props {
	headerLabel: string;
  properties:  Array<SchemaProperty>
}

function buildProperties(properties: Array<SchemaProperty>, isObjectProperty = false) {
	const allProperties = properties.map((property) => {
		return buildProperty(property, isObjectProperty)
	})

	if (isObjectProperty) {
		return (
			<div className={styles.ObjectProperties}>
				{allProperties}
			</div>
		)
	}

	return allProperties;
}

function buildProperty(property: SchemaProperty, isObjectProperty = false) {
	const classes = classNames([
		!isObjectProperty && styles.Property,
		isObjectProperty && styles.ObjectProperty
	]);

	return (
		<div className={classes} key={property.name}>
			<div className={styles.Name}>
				<p>{property.name}</p>
				<span>{property.type}</span>
			</div>
			<div className={styles.Description}>
				{property.description}
			</div>
			{property.objectProperties !== undefined && (
				buildProperties(property.objectProperties, true)
			)}
		</div>
	)


}

const ResourcePropertiesList: React.FunctionComponent<Props> = ({headerLabel, properties}) => {

	return (
		<div className={styles.ResourcePropertiesList}>
		  <div className={styles.Header}>
				{headerLabel}
      </div>

			{buildProperties(properties)}
		</div>
	);
};

export default ResourcePropertiesList;
