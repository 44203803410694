import React from "react";
import { CodeBlock } from "@atlaskit/code"
import { CodeBlockDarkTheme } from "../../utils"

const styles = require("./ApiResponseCodeBlock.module.scss");

interface Props {
	response: any;
}

const ApiResponseCodeBlock: React.FunctionComponent<Props> = ({response}) => {
	const exampleResponse = JSON.stringify(response, null, 2)

	return (
		<div className={styles.ApiResponseCodeBlock}>
			<div className={styles.Header}>
				Example Response
			</div>
			<CodeBlock theme={CodeBlockDarkTheme} language="json" text={exampleResponse} showLineNumbers={false} />
		</div>
	);
};

export default ApiResponseCodeBlock;
