import React from "react";
import { CrumbTrail, SchemaProperty } from "../../../interfaces"
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs"
import ResourcePropertiesList from "../../ResourcePropertiesList/ResourcePropertiesList"

const styles = require("./Resources.module.scss");

interface Props {
	properties: Array<SchemaProperty>
	breadcrumbs: CrumbTrail
}

const Schema: React.FunctionComponent<Props> = ({properties, breadcrumbs}) => {
	return (
		<div className={styles.Schema}>
		  <div className={styles.Content}>
				<Breadcrumbs trail={breadcrumbs} />

				<ResourcePropertiesList headerLabel="Properties" properties={properties} />
			</div>
		</div>
	);
};

export default Schema;
