import React from "react";
import { CrumbTrail } from "../../../interfaces"
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs"
import ResourcePropertiesList from "../../ResourcePropertiesList/ResourcePropertiesList"
import ApiResponseCodeBlock from "../../ApiResponseCode/ApiResponseCodeBlock"

const styles = require("./Resources.module.scss");

interface Props {
  description: string;
  endpoint: {
    verb: string;
    url: string;
  },
  inline: boolean;
  parameters?: Array<{
    name: string;
    type: string;
    description: string
  }>,
  body?: Array<{
    name: string;
    type: string;
    description: string
  }>,
  response: {[key: string]: any}
  breadcrumbs: CrumbTrail|null
}

const Endpoint: React.FunctionComponent<Props> = ({description, endpoint, parameters = [], body = [], response, breadcrumbs, inline = false}) => {
  const content = (
    <React.Fragment>
      {breadcrumbs !== null && (
        <Breadcrumbs trail={breadcrumbs} />
      )}

      <div className={styles.Description}>
        {description}
      </div>

      <div className={styles.Structure}>
        <div className={styles.Verb}>{endpoint.verb}</div>
        <div className={styles.Url}>{endpoint.url}</div>
      </div>

      {parameters.length > 0 && <ResourcePropertiesList headerLabel="Request Parameters" properties={parameters} />}
      {body.length > 0 && <ResourcePropertiesList headerLabel="Request Body" properties={body} />}
      <ApiResponseCodeBlock response={response} />
    </React.Fragment>
  )

  if (inline) {
    return (
      <div className={styles.Endpoint}>
        {content}
      </div>
    )
  }

  return (
    <div className={styles.Endpoint}>
      <div className={styles.Content}>
        {content}
      </div>
    </div>
  );
};

export default Endpoint;
