import React from "react";
import {graphql} from 'gatsby';
import Endpoint from "./Resources/Endpoint"
import Schema from "./Resources/Schema"
import { CrumbTrail } from "../../interfaces"

const styles = require("./Layouts.module.scss");

interface Props {
	data: any;
	pageContext: {
		resource: {
			id: string;
		},
		breadcrumbs: CrumbTrail
	}
}

const Resource: React.FunctionComponent<Props> = ({data, pageContext}) => {

	const content = JSON.parse(data.file.internal.content)

	if (content.type === 'endpoint') {
		return (
			<Endpoint {...content} breadcrumbs={pageContext.breadcrumbs} />
		)
	}


	return (
		<Schema {...content} breadcrumbs={pageContext.breadcrumbs} />
	);
};

export const query = graphql`
	query($id: String!) {
		file(id: {eq: $id}) {
			name
			id
			internal {
				content
			}
		}
	}
`

export default Resource;
